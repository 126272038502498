import weldLogo from "@/assets/weld_white.svg";
import config from "@weldhq/integration-logos";
import activecampaign from "@weldhq/integration-logos/activecampaign.svg";
import adobe_commerce from "@weldhq/integration-logos/adobe-commerce.svg";
import airtable from "@weldhq/integration-logos/airtable.svg";
import amazon_ads from "@weldhq/integration-logos/amazon-ads.svg";
import amazon_aurora_mysql from "@weldhq/integration-logos/amazon-aurora-mysql.svg";
import amazon_aurora_postgresql from "@weldhq/integration-logos/amazon-aurora-postgresql.svg";
import amazon_mws from "@weldhq/integration-logos/amazon-mws.svg";
import amazon_selling_api_oauth from "@weldhq/integration-logos/amazon-selling-api-oauth.svg";
import amazon_selling_api from "@weldhq/integration-logos/amazon-selling-api.svg";
import amazon_vendor_central from "@weldhq/integration-logos/amazon-vendor-central.svg";
import app_store_connect from "@weldhq/integration-logos/app-store-connect.svg";
import appsflyer from "@weldhq/integration-logos/appsflyer.svg";
import asana from "@weldhq/integration-logos/asana.svg";
import autopilot from "@weldhq/integration-logos/autopilot.svg";
import aws_lambda from "@weldhq/integration-logos/aws-lambda.svg";
import aws_s3 from "@weldhq/integration-logos/aws-s3.svg";
import azure_analytics from "@weldhq/integration-logos/azure-analytics.svg";
import azure_blob from "@weldhq/integration-logos/azure-blob.svg";
import azure_cosmos from "@weldhq/integration-logos/azure-cosmos.svg";
import azure_sql from "@weldhq/integration-logos/azure-sql.svg";
import azure_synapse from "@weldhq/integration-logos/azure-synapse.svg";
import bigcommerce from "@weldhq/integration-logos/bigcommerce.svg";
import bigquery from "@weldhq/integration-logos/bigquery.svg";
import business_central from "@weldhq/integration-logos/business-central.svg";
import chargebee from "@weldhq/integration-logos/chargebee.svg";
import chartmogul from "@weldhq/integration-logos/chartmogul.svg";
import clerkio from "@weldhq/integration-logos/clerkio.svg";
import clickup from "@weldhq/integration-logos/clickup.svg";
import contact_form_seven from "@weldhq/integration-logos/contact-form-seven.svg";
import csv from "@weldhq/integration-logos/csv.svg";
import custom_integration_v2 from "@weldhq/integration-logos/custom-integration-v2.svg";
import custom_integration from "@weldhq/integration-logos/custom-integration.svg";
import customer_io from "@weldhq/integration-logos/customer-io.svg";
import daisycon from "@weldhq/integration-logos/daisycon.svg";
import dandomain_webshop from "@weldhq/integration-logos/dandomain-webshop.svg";
import databricks from "@weldhq/integration-logos/databricks.svg";
import dbt_cloud from "@weldhq/integration-logos/dbt-cloud.svg";
import discourse from "@weldhq/integration-logos/discourse.svg";
import dixa from "@weldhq/integration-logos/dixa.svg";
import drupal from "@weldhq/integration-logos/drupal.svg";
import dynamodb from "@weldhq/integration-logos/dynamodb.svg";
import economic from "@weldhq/integration-logos/economic.svg";
import email from "@weldhq/integration-logos/email.svg";
import esmiley from "@weldhq/integration-logos/esmiley.svg";
import excel from "@weldhq/integration-logos/excel.svg";
import facebook_ads from "@weldhq/integration-logos/facebook-ads.svg";
import facebook_conversions from "@weldhq/integration-logos/facebook-conversions.svg";
import facebook_offline_conversions from "@weldhq/integration-logos/facebook-offline-conversions.svg";
import facebook_pages from "@weldhq/integration-logos/facebook-pages.svg";
import firebase from "@weldhq/integration-logos/firebase.svg";
import forex_v2 from "@weldhq/integration-logos/forex-v2.svg";
import forex from "@weldhq/integration-logos/forex.svg";
import freshchat from "@weldhq/integration-logos/freshchat.svg";
import freshdesk from "@weldhq/integration-logos/freshdesk.svg";
import freshsales from "@weldhq/integration-logos/freshsales.svg";
import freshservice from "@weldhq/integration-logos/freshservice.svg";
import ftp from "@weldhq/integration-logos/ftp.svg";
import google_ads from "@weldhq/integration-logos/google-ads.svg";
import google_analytics_4 from "@weldhq/integration-logos/google-analytics-4.svg";
import google_analytics from "@weldhq/integration-logos/google-analytics.svg";
import google_cloud_storage from "@weldhq/integration-logos/google-cloud-storage.svg";
import google_drive from "@weldhq/integration-logos/google-drive.svg";
import google_drive__excel from "@weldhq/integration-logos/google-drive__excel.svg";
import google_my_business_profile from "@weldhq/integration-logos/google-my-business-profile.svg";
import google_search_console from "@weldhq/integration-logos/google-search-console.svg";
import google_sheets_service_account from "@weldhq/integration-logos/google-sheets-service-account.svg";
import google_sheets from "@weldhq/integration-logos/google-sheets.svg";
import google_video from "@weldhq/integration-logos/google-video.svg";
import gorgias from "@weldhq/integration-logos/gorgias.svg";
import hairtools from "@weldhq/integration-logos/hairtools.svg";
import harvest from "@weldhq/integration-logos/harvest.svg";
import heapsgo from "@weldhq/integration-logos/heapsgo.svg";
import heroku_postgres from "@weldhq/integration-logos/heroku-postgres.svg";
import hubspot_v2 from "@weldhq/integration-logos/hubspot-v2.svg";
import hubspot from "@weldhq/integration-logos/hubspot.svg";
import instagram_business from "@weldhq/integration-logos/instagram-business.svg";
import intercom from "@weldhq/integration-logos/intercom.svg";
import invision_community from "@weldhq/integration-logos/invision-community.svg";
import iterable from "@weldhq/integration-logos/iterable.svg";
import jetpack from "@weldhq/integration-logos/jetpack.svg";
import joomla from "@weldhq/integration-logos/joomla.svg";
import klaviyo from "@weldhq/integration-logos/klaviyo.svg";
import linkedin_ads from "@weldhq/integration-logos/linkedin-ads.svg";
import loaded_commerce from "@weldhq/integration-logos/loaded-commerce.svg";
import looker from "@weldhq/integration-logos/looker.svg";
import magento_on_amazon_rds from "@weldhq/integration-logos/magento-on-amazon-rds.svg";
import magento from "@weldhq/integration-logos/magento.svg";
import mailchimp from "@weldhq/integration-logos/mailchimp.svg";
import mariadb from "@weldhq/integration-logos/mariadb.svg";
import marketo from "@weldhq/integration-logos/marketo.svg";
import medusa from "@weldhq/integration-logos/medusa.svg";
import memberpress from "@weldhq/integration-logos/memberpress.svg";
import metabase from "@weldhq/integration-logos/metabase.svg";
import meteor_via_mongodb from "@weldhq/integration-logos/meteor-via-mongodb.svg";
import microsoft_ads from "@weldhq/integration-logos/microsoft-ads.svg";
import microsoft_dynamics_365 from "@weldhq/integration-logos/microsoft-dynamics-365.svg";
import mixpanel_destination from "@weldhq/integration-logos/mixpanel-destination.svg";
import mixpanel_source from "@weldhq/integration-logos/mixpanel-source.svg";
import mode from "@weldhq/integration-logos/mode.svg";
import monday from "@weldhq/integration-logos/monday.svg";
import mongodb_sharded_cluster from "@weldhq/integration-logos/mongodb-sharded-cluster.svg";
import mongodb from "@weldhq/integration-logos/mongodb.svg";
import monster_insights from "@weldhq/integration-logos/monster-insights.svg";
import mssql from "@weldhq/integration-logos/mssql.svg";
import mysql_on_amazon_rds from "@weldhq/integration-logos/mysql-on-amazon-rds.svg";
import mysql_on_azure_database from "@weldhq/integration-logos/mysql-on-azure-database.svg";
import mysql_on_google_cloud_sql from "@weldhq/integration-logos/mysql-on-google-cloud-sql.svg";
import mysql from "@weldhq/integration-logos/mysql.svg";
import netsuite from "@weldhq/integration-logos/netsuite.svg";
import netvisor from "@weldhq/integration-logos/netvisor.svg";
import node_bb from "@weldhq/integration-logos/node-bb.svg";
import onedrive from "@weldhq/integration-logos/onedrive.svg";
import openweather from "@weldhq/integration-logos/openweather.svg";
import os_commerce from "@weldhq/integration-logos/os-commerce.svg";
import pardot from "@weldhq/integration-logos/pardot.svg";
import pendo from "@weldhq/integration-logos/pendo.svg";
import phoenix_via_postgresql from "@weldhq/integration-logos/phoenix-via-postgresql.svg";
import pinterest_ads from "@weldhq/integration-logos/pinterest-ads.svg";
import pipedrive from "@weldhq/integration-logos/pipedrive.svg";
import plausible_analytics from "@weldhq/integration-logos/plausible-analytics.svg";
import postgresql_on_azure_database from "@weldhq/integration-logos/postgresql-on-azure-database.svg";
import postgresql_on_google_cloud from "@weldhq/integration-logos/postgresql-on-google-cloud.svg";
import postgresql_on_rds from "@weldhq/integration-logos/postgresql-on-rds.svg";
import postgresql from "@weldhq/integration-logos/postgresql.svg";
import powerbi from "@weldhq/integration-logos/powerbi.svg";
import presta_shop from "@weldhq/integration-logos/presta-shop.svg";
import qualtrics from "@weldhq/integration-logos/qualtrics.svg";
import quickbooks from "@weldhq/integration-logos/quickbooks.svg";
import recharge from "@weldhq/integration-logos/recharge.svg";
import recurly from "@weldhq/integration-logos/recurly.svg";
import reddit from "@weldhq/integration-logos/reddit.svg";
import redshift from "@weldhq/integration-logos/redshift.svg";
import rotaready from "@weldhq/integration-logos/rotaready.svg";
import salesforce_commerce_cloud from "@weldhq/integration-logos/salesforce-commerce-cloud.svg";
import salesforce from "@weldhq/integration-logos/salesforce.svg";
import segment from "@weldhq/integration-logos/segment.svg";
import sevenrooms from "@weldhq/integration-logos/sevenrooms.svg";
import sftp from "@weldhq/integration-logos/sftp.svg";
import sharepoint from "@weldhq/integration-logos/sharepoint.svg";
import shopify_api_key from "@weldhq/integration-logos/shopify-api-key.svg";
import shopify_plus from "@weldhq/integration-logos/shopify-plus.svg";
import shopify from "@weldhq/integration-logos/shopify.svg";
import six_sense from "@weldhq/integration-logos/six-sense.svg";
import slack from "@weldhq/integration-logos/slack.svg";
import snapchat_ads from "@weldhq/integration-logos/snapchat-ads.svg";
import snowflake from "@weldhq/integration-logos/snowflake.svg";
import spree_commerce from "@weldhq/integration-logos/spree-commerce.svg";
import sql_server_on_rds from "@weldhq/integration-logos/sql-server-on-rds.svg";
import sqlServer from "@weldhq/integration-logos/sqlServer.svg";
import stripe from "@weldhq/integration-logos/stripe.svg";
import supabase from "@weldhq/integration-logos/supabase.svg";
import tableau from "@weldhq/integration-logos/tableau.svg";
import tapfiliate from "@weldhq/integration-logos/tapfiliate.svg";
import tiktok_ads from "@weldhq/integration-logos/tiktok-ads.svg";
import typeform from "@weldhq/integration-logos/typeform.svg";
import typo_three from "@weldhq/integration-logos/typo-three.svg";
import vitally from "@weldhq/integration-logos/vitally.svg";
import weld_bigquery from "@weldhq/integration-logos/weld-bigquery.svg";
import woocommerce from "@weldhq/integration-logos/woocommerce.svg";
import wordfence_security from "@weldhq/integration-logos/wordfence-security.svg";
import wordpress from "@weldhq/integration-logos/wordpress.svg";
import workday from "@weldhq/integration-logos/workday.svg";
import xAds from "@weldhq/integration-logos/xAds.svg";
import xero from "@weldhq/integration-logos/xero.svg";
import yoast_seo from "@weldhq/integration-logos/yoast-seo.svg";
import youtube_analytics from "@weldhq/integration-logos/youtube-analytics.svg";
import zendesk from "@weldhq/integration-logos/zendesk.svg";

const integrations = {
  activecampaign: activecampaign,
  "adobe-commerce": adobe_commerce,
  airtable: airtable,
  "amazon-ads": amazon_ads,
  "amazon-aurora-mysql": amazon_aurora_mysql,
  "amazon-aurora-postgresql": amazon_aurora_postgresql,
  "amazon-mws": amazon_mws,
  "amazon-selling-api-oauth": amazon_selling_api_oauth,
  "amazon-selling-api": amazon_selling_api,
  "amazon-vendor-central": amazon_vendor_central,
  "app-store-connect": app_store_connect,
  appsflyer: appsflyer,
  asana: asana,
  autopilot: autopilot,
  "aws-lambda": aws_lambda,
  "aws-s3": aws_s3,
  "aws-s3__csv": csv,
  "azure-analytics": azure_analytics,
  "azure-blob": azure_blob,
  "azure-cosmos": azure_cosmos,
  "azure-sql": azure_sql,
  "azure-synapse": azure_synapse,
  bigcommerce: bigcommerce,
  bigquery: bigquery,
  "business-central": business_central,
  chargebee: chargebee,
  chartmogul: chartmogul,
  clerkio: clerkio,
  clickup: clickup,
  "contact-form-seven": contact_form_seven,
  "custom-integration-v2": custom_integration_v2,
  "custom-integration": custom_integration,
  "customer-io": customer_io,
  daisycon: daisycon,
  "dandomain-webshop": dandomain_webshop,
  databricks: databricks,
  "dbt-cloud": dbt_cloud,
  discourse: discourse,
  dixa: dixa,
  drupal: drupal,
  dynamodb: dynamodb,
  economic: economic,
  email: email,
  esmiley: esmiley,
  excel: excel,
  "facebook-ads": facebook_ads,
  "facebook-conversions": facebook_conversions,
  "facebook-offline-conversions": facebook_offline_conversions,
  "facebook-pages": facebook_pages,
  firebase: firebase,
  "forex-v2": forex_v2,
  forex: forex,
  freshchat: freshchat,
  freshdesk: freshdesk,
  freshsales: freshsales,
  freshservice: freshservice,
  ftp: ftp,
  ftp__csv: csv,
  "google-ads": google_ads,
  "google-analytics-4": google_analytics_4,
  "google-analytics": google_analytics,
  "google-cloud-storage": google_cloud_storage,
  "google-drive": google_drive,
  "google-drive__csv": csv,
  "google-drive__excel": google_drive__excel,
  "google-my-business-profile": google_my_business_profile,
  "google-search-console": google_search_console,
  "google-sheets-service-account": google_sheets_service_account,
  "google-sheets": google_sheets,
  "google-video": google_video,
  gorgias: gorgias,
  hairtools: hairtools,
  harvest: harvest,
  heapsgo: heapsgo,
  "heroku-postgres": heroku_postgres,
  "hubspot-v2": hubspot_v2,
  hubspot: hubspot,
  "instagram-business": instagram_business,
  intercom: intercom,
  "invision-community": invision_community,
  iterable: iterable,
  jetpack: jetpack,
  joomla: joomla,
  klaviyo: klaviyo,
  "linkedin-ads": linkedin_ads,
  "loaded-commerce": loaded_commerce,
  looker: looker,
  "magento-on-amazon-rds": magento_on_amazon_rds,
  magento: magento,
  mailchimp: mailchimp,
  mariadb: mariadb,
  marketo: marketo,
  medusa: medusa,
  memberpress: memberpress,
  metabase: metabase,
  "meteor-via-mongodb": meteor_via_mongodb,
  "microsoft-ads": microsoft_ads,
  "microsoft-dynamics-365": microsoft_dynamics_365,
  "mixpanel-destination": mixpanel_destination,
  "mixpanel-source": mixpanel_source,
  mode: mode,
  monday: monday,
  "mongodb-sharded-cluster": mongodb_sharded_cluster,
  mongodb: mongodb,
  "monster-insights": monster_insights,
  mssql: mssql,
  "mysql-on-amazon-rds": mysql_on_amazon_rds,
  "mysql-on-azure-database": mysql_on_azure_database,
  "mysql-on-google-cloud-sql": mysql_on_google_cloud_sql,
  mysql: mysql,
  netsuite: netsuite,
  netvisor: netvisor,
  "node-bb": node_bb,
  onedrive,
  onedrive__csv: csv,
  onedrive__excel: excel,
  openweather: openweather,
  "os-commerce": os_commerce,
  pardot: pardot,
  pendo: pendo,
  "phoenix-via-postgresql": phoenix_via_postgresql,
  "pinterest-ads": pinterest_ads,
  pipedrive: pipedrive,
  "plausible-analytics": plausible_analytics,
  "postgresql-on-azure-database": postgresql_on_azure_database,
  "postgresql-on-google-cloud": postgresql_on_google_cloud,
  "postgresql-on-rds": postgresql_on_rds,
  postgresql: postgresql,
  powerbi: powerbi,
  "presta-shop": presta_shop,
  qualtrics,
  quickbooks: quickbooks,
  recharge: recharge,
  recurly: recurly,
  reddit: reddit,
  redshift: redshift,
  rotaready: rotaready,
  "salesforce-commerce-cloud": salesforce_commerce_cloud,
  salesforce: salesforce,
  segment: segment,
  sevenrooms: sevenrooms,
  sftp: sftp,
  sftp__csv: csv,
  sharepoint,
  sharepoint__csv: csv,
  sharepoint__excel: excel,
  "shopify-api-key": shopify_api_key,
  "shopify-plus": shopify_plus,
  shopify: shopify,
  "six-sense": six_sense,
  slack: slack,
  "snapchat-ads": snapchat_ads,
  snowflake: snowflake,
  "spree-commerce": spree_commerce,
  "sql-server-on-rds": sql_server_on_rds,
  sqlServer: sqlServer,
  stripe: stripe,
  supabase: supabase,
  tableau: tableau,
  tapfiliate: tapfiliate,
  "tiktok-ads": tiktok_ads,
  "tiktok-organic": tiktok_ads,
  typeform: typeform,
  "typo-three": typo_three,
  vitally: vitally,
  weld: weldLogo,
  "weld-bigquery": weld_bigquery,
  woocommerce: woocommerce,
  "wordfence-security": wordfence_security,
  wordpress: wordpress,
  workday: workday,
  xAds: xAds,
  xero: xero,
  "yoast-seo": yoast_seo,
  "youtube-analytics": youtube_analytics,
  zendesk: zendesk,
};

export type IntegrationId = keyof typeof integrations;

const integrationsConfig = Object.freeze(
  Object.entries(integrations).reduce(
    (acc, [key, value]) => {
      const id = key as IntegrationId;
      let url = value;
      let c = config[key as keyof typeof config];
      if (/\w+__\w+/.test(key)) {
        c = config[key.split("__")[1] as keyof typeof config];
      }
      acc[id] = {
        url,
        id,
        bg: c?.bg ?? "hsl(0, 0%, 92%)",
        bg_dark: c?.bg_dark ?? "hsl(0, 0%, 40%)",
      };
      return acc;
    },
    {} as Record<
      IntegrationId,
      {
        url: string;
        id: IntegrationId;
        bg: string;
        bg_dark: string;
      }
    >,
  ),
);

export function getIntegrationLogos() {
  return integrationsConfig;
}

export function getIntegrationLogo(
  integrationId: IntegrationId | (string & {}),
) {
  return integrationsConfig[integrationId as IntegrationId];
}
