import { Reorder } from "framer-motion";
import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigateWithSlug } from "routes";
import { v4 as uuid } from "uuid";

import { ModelFolder } from "@/apollo/types";
import { Button, IconButton } from "@/components/elements/Button";
import Tooltip from "@/components/elements/Tooltip";
import DashboardIcon from "@/components/icons/DashboardIcon";
import classNames from "@/helpers/classNames";
import usePrevious from "@/hooks/usePrevious";
import { useCoreMetricsOpen } from "@/pages/Metrics/CoreMetricsDrawer";
import {
  ModelType,
  useModelEditorDirtyState,
  useModelEditorDispatch,
  useModelEditorState,
} from "@/pages/ModelTool/ModelEditorStore";
import {
  usePreviewOpenAtom,
  usePreviewsAtom,
} from "@/pages/ModelTool/Preview/Preview";
import { useHistorySidebar } from "@/pages/ModelTool/QueryEditor/history/HistorySideBar";
import { useDashboard } from "@/pages/ModelTool/Visualize/useDashboards";
import { FolderIcon } from "@/pages/ModelTool/components/FolderIcon";
import { ModelIcon } from "@/pages/ModelTool/components/ModelIcon/ModelIcon";
import { useModel } from "@/pages/ModelTool/hooks/useCurrentModel";
import {
  FolderPathRenderer,
  useFullFolderPath,
  useFullModelPathRenderer,
} from "@/pages/ModelTool/hooks/useFullPathNames";
import { createModelToolStorageKey } from "@/pages/ModelTool/utils/createLocalStorageKey";
import { isDraftModel } from "@/pages/ModelTool/utils/modelUtils";
import { useCurrentAccount } from "@/providers/account";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import {
  PlusIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { NavigateOptions } from "@tanstack/react-location";

type Tab = {
  id: string;
  label?: string;
  modelId?: string;
  dashboardId?: string;
  tileId?: string;
  latestPreviewId?: string;
  type: ModelType | "empty" | "dashboard";
};
const tabsAtom = atomFamily((accountId: string) =>
  atom<Tab[]>(getSavedTabs(accountId)),
);

const getSavedTabs = (accountId: string) => {
  try {
    const savedTabs = localStorage.getItem(
      createModelToolStorageKey("openTabs", accountId),
    );
    if (savedTabs) {
      return JSON.parse(savedTabs) as Tab[];
    }
    return [];
  } catch (error) {
    return [];
  }
};

const currentTabSelectedAtom = atomFamily((accountId: string) =>
  atom<string | null>(getSavedCurrentTab(accountId)),
);

const getSavedCurrentTab = (accountId: string) => {
  try {
    return (
      localStorage.getItem(
        createModelToolStorageKey("currentTab", accountId),
      ) || null
    );
  } catch (error) {
    return null;
  }
};

const useModelTabs = () => {
  const { id } = useCurrentAccount();
  return useAtom(tabsAtom(id));
};

const useAutoSaveTabs = () => {
  const { id } = useCurrentAccount();
  const [tabs] = useModelTabs();
  useEffect(() => {
    try {
      localStorage.setItem(
        createModelToolStorageKey("openTabs", id),
        JSON.stringify(tabs),
      );
    } catch (error) {}
  }, [id, tabs]);
};

const useCurrentTabAtom = () => {
  const { id } = useCurrentAccount();
  const [curTab, setCurTab] = useAtom(currentTabSelectedAtom(id));
  const [tabs] = useModelTabs();

  const currentTab = useMemo(
    () => tabs.find((t) => t.id === curTab),
    [tabs, curTab],
  );

  const setCurrentTab = useCallback(
    (tabId: string | null) => {
      setCurTab(tabId);
      try {
        if (tabId)
          localStorage.setItem(
            createModelToolStorageKey("currentTab", id),
            tabId,
          );
        else
          localStorage.removeItem(createModelToolStorageKey("currentTab", id));
      } catch (error) {}
    },
    [id, setCurTab],
  );

  return { currentTab, setCurrentTab };
};

const useCurrentTab = () => useCurrentTabAtom().currentTab;

const useSetCurrentTab = () => {
  const { currentTab, setCurrentTab } = useCurrentTabAtom();
  const [tabs, setTabs] = useModelTabs();
  const navigate = useNavigateWithSlug();
  return useCallback(
    (
      tab: Omit<Tab, "id">,
      navigateOptions: Pick<
        NavigateOptions<{}>,
        "search" | "replace" | "hash"
      > = { search: true },
    ) => {
      const existingTab = tabs.find((t) => {
        if (tab.type === "model" || tab.type === "draft") {
          return t.modelId === tab.modelId;
        }
        if (tab.type === "dashboard") {
          return t.dashboardId === tab.dashboardId;
        }
        return false;
      });

      if (existingTab) {
        setCurrentTab(existingTab.id);
        navigate({ ...getTabLink(existingTab), ...navigateOptions });
        return;
      }

      if (currentTab?.type === "empty") {
        const tabIndex = tabs.findIndex((t) => t.id === currentTab.id);
        if (tabIndex !== -1) {
          const updatedTab = { ...tabs[tabIndex], ...tab };
          const newTabs = tabs.map((t) =>
            t.id === currentTab.id ? updatedTab : t,
          );
          setTabs(newTabs);
          navigate({ ...getTabLink(updatedTab), ...navigateOptions });
          return;
        }
      }

      const newTab = {
        id: uuid(),
        ...tab,
      };

      setTabs((prevTabs) => {
        return [...prevTabs, { ...newTab }];
      });

      setCurrentTab(newTab.id);
      navigate({ ...getTabLink(newTab), ...navigateOptions });
    },
    [navigate, currentTab, setCurrentTab, tabs, setTabs],
  );
};

export function useCreateNewEmptyTab() {
  const { setCurrentTab } = useCurrentTabAtom();
  const [, setTabs] = useModelTabs();
  const navigate = useNavigateWithSlug();

  return useCallback(() => {
    const newTab: Tab = {
      id: uuid(),
      type: "empty",
    };
    setTabs((prev) => [...prev, { ...newTab }]);
    setCurrentTab(newTab.id);
    navigate(getTabLink(newTab));
    return newTab.id;
  }, [navigate, setTabs, setCurrentTab]);
}

export function useCloseCurrentTab() {
  const { currentTab, setCurrentTab } = useCurrentTabAtom();
  const [tabs, setTabs] = useModelTabs();
  const navigate = useNavigateWithSlug();

  return useCallback(() => {
    if (!currentTab) return;
    const currentTabIndex = tabs.findIndex((tab) => tab.id === currentTab.id);
    if (currentTabIndex === -1) return;
    const filteredTabs = tabs.filter((tab) => tab.id !== currentTab.id);
    setTabs(filteredTabs);
    if (filteredTabs.length > 0) {
      const newCurrentTabIndex = Math.max(0, currentTabIndex - 1);
      const newCurrentTab = filteredTabs[newCurrentTabIndex];
      setCurrentTab(newCurrentTab.id);
      navigate(getTabLink(newCurrentTab));
    } else {
      setCurrentTab(null);
      navigate(getTabLink(undefined));
    }
  }, [navigate, currentTab, setCurrentTab, setTabs, tabs]);
}

export const useOpenModelInTab = () => {
  const handleOpenTab = useSetCurrentTab();
  return useCallback(
    (
      params: {
        modelId: string;
        type?: ModelType;
        dashboardId?: string;
        tileId?: string;
      },
      navigateOptions?: Pick<
        NavigateOptions<{}>,
        "search" | "replace" | "hash"
      >,
    ) => {
      handleOpenTab({ type: "model", ...params }, navigateOptions);
    },
    [handleOpenTab],
  );
};

export const useOpenDraftInTab = () => {
  const handleOpenTab = useSetCurrentTab();
  return useCallback(
    (draftId: string, draftNr: number, openNew?: true) => {
      handleOpenTab({
        modelId: draftId,
        type: "draft",
        label: "Unsaved draft" + (draftNr > 1 ? ` (${draftNr})` : ""),
      });
    },
    [handleOpenTab],
  );
};

export const useOpenDashboardInTab = () => {
  const handleOpenTab = useSetCurrentTab();
  return useCallback(
    (dashboardId: string, dashboardName: string, openNew?: true) => {
      handleOpenTab({
        dashboardId,
        type: "dashboard",
        label: dashboardName,
      });
    },
    [handleOpenTab],
  );
};

export const useStoreLatestPreviewForTab = () => {
  const [, setTabs] = useModelTabs();
  const currentTab = useCurrentTab();

  return useCallback(
    (previewId: string) => {
      setTabs((prevTabs) => {
        return prevTabs.map((tab) =>
          tab.id === currentTab?.id
            ? { ...tab, latestPreviewId: previewId }
            : tab,
        );
      });
    },
    [currentTab?.id, setTabs],
  );
};

export const useCloseRemovedTab = () => {
  const { currentTab, setCurrentTab } = useCurrentTabAtom();

  const [, setTabs] = useModelTabs();

  const navigate = useNavigateWithSlug();

  const closeRemovedModelTab = useCallback(
    (type: "model" | "dashboard", id: string) => {
      let newCurrentTab: Tab | undefined;

      setTabs((p) => {
        const filteredTabs = p.filter((tab) => {
          if (type === "dashboard") return tab.dashboardId !== id;
          return tab.modelId !== id;
        });
        newCurrentTab = filteredTabs[0];

        return filteredTabs;
      });

      if (currentTab?.modelId === id || currentTab?.dashboardId === id) {
        setCurrentTab(newCurrentTab?.id ?? null);
        navigate(getTabLink(newCurrentTab));
      }
    },
    [
      currentTab?.dashboardId,
      currentTab?.modelId,
      navigate,
      setCurrentTab,
      setTabs,
    ],
  );

  return closeRemovedModelTab;
};

export const useCurrentTabLink = () => {
  const currentTab = useCurrentTab();
  const routerInput = useTabLink(currentTab ?? undefined);

  return useMemo(() => {
    return (
      routerInput.to +
      (typeof routerInput.search === "string" ? "?" + routerInput.search : "")
    );
  }, [routerInput]);
};

const useTabLink = (tab?: Tab) => {
  return getTabLink(tab);
};

const getTabLink = (
  tab?: Tab,
): Parameters<ReturnType<typeof useNavigateWithSlug>>[0] => {
  if (tab?.modelId && tab.type === "model") {
    return {
      to: `/editor/${tab.modelId}`,
      search: (prev) => ({
        ...prev,
        dashboardId: tab.dashboardId,
        tileId: tab.tileId,
      }),
    };
  }
  if (tab?.modelId && tab.type === "draft") {
    return { to: `/editor/draft/${tab.modelId}`, search: true };
  }
  if (tab?.dashboardId && tab.type === "dashboard") {
    return { to: `/editor/dashboard/${tab.dashboardId}`, search: true };
  }

  return { to: `/editor`, search: true };
};

const TabItem = forwardRef<HTMLDivElement, { tab: Tab; isActive: boolean }>(
  (props, ref) => {
    const { setCurrentTab } = useCurrentTabAtom();

    const [previews] = usePreviewsAtom();
    const [currentPreview, setCurrentPreview] = usePreviewOpenAtom();

    const tabLink = useTabLink(props.tab);

    const navigate = useNavigateWithSlug();
    const handleClick = () => {
      setCurrentTab(props.tab.id);
      navigate(tabLink);

      //switch preview query if tab changed and previous had a query called:
      const latestTabPreview = previews.find(
        (p) => p.id === props.tab.latestPreviewId,
      );

      if (latestTabPreview && !!currentPreview) {
        setCurrentPreview(latestTabPreview.id);
      }
    };

    const [tabs, setTabs] = useModelTabs();
    const handleClose = () => {
      setTabs((tabs) => tabs.filter((tab) => tab.id !== props.tab.id));
      const newCurrent =
        tabs.filter((tab) => tab.id !== props.tab.id)[0] ??
        (null as Tab | null);
      if (props.isActive) {
        setCurrentTab(newCurrent?.id ?? null);
        navigate(getTabLink(newCurrent));
      }
    };

    const renderDisplay = () => {
      if (props.tab.type === "model" && props.tab.modelId)
        return (
          <ModelTabDisplay
            modelId={props.tab.modelId}
            dashboardId={props.tab.dashboardId}
          />
        );
      if (props.tab.type === "dashboard" && props.tab.dashboardId)
        return (
          <DashboardTabDisplay
            dashboardId={props.tab.dashboardId}
            label={props.tab.label}
          />
        );

      if (props.tab.type === "draft" && props.tab.modelId) {
        return <span>{props.tab.label || "New tab"}</span>;
      }

      return (
        <span className="italic text-gray-500 dark:text-gray-400">
          {props.tab.label || "New tab"}
        </span>
      );
    };

    return (
      <div
        ref={ref}
        className={classNames(
          "group relative flex h-9 items-center border-b border-t-2 bg-gray-100 pl-3 text-gray-500 dark:bg-gray-800",
          props.isActive &&
            "border-b-white border-t-blue-600 bg-white text-gray-800 dark:border-b-[#1e1e1e] dark:bg-[#1e1e1e] dark:text-white",
        )}
        onClick={() => handleClick()}
        role="button"
        tabIndex={0}
      >
        <span className="whitespace-nowrap text-xs leading-9">
          {renderDisplay()}
        </span>
        <div
          className={classNames(
            "my-auto flex w-7 items-center justify-center opacity-0 group-hover:opacity-100",
            props.isActive && "opacity-100",
          )}
        >
          <IconButton
            size="xs"
            variant="ghost"
            icon={<XMarkIcon className="h-4 w-4" />}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          />
        </div>
      </div>
    );
  },
);

const ModelTabDisplay = (props: { modelId: string; dashboardId?: string }) => {
  const { model } = useModel(props.modelId);
  const { dashboard } = useDashboard(props.dashboardId ?? "");
  const fullPath = useFullModelPathRenderer(model);
  return (
    <div
      className="flex items-center space-x-2"
      title={`${
        props.dashboardId && dashboard ? `${dashboard.name} > ` : ""
      }${fullPath}`}
    >
      {model && <ModelIcon model={model} />}
      <span>{model?.name}</span>
    </div>
  );
};

const DashboardTabDisplay = (props: {
  dashboardId: string;
  label?: string;
}) => {
  const { dashboard } = useDashboard(props.dashboardId);
  const folderPath = useFullFolderPath(dashboard?.folderId);
  const name = dashboard?.name ?? props.label ?? "Unknown dashboard";
  return (
    <div
      className="flex items-center space-x-2"
      title={`${folderPath.join(".")}.${name}`}
    >
      <DashboardIcon className="h-4 w-4" />
      <span>{name}</span>
    </div>
  );
};

export const EditorTabs = () => {
  const [tabs, setTabs] = useModelTabs();
  useAutoSaveTabs();
  const { currentTab, setCurrentTab } = useCurrentTabAtom();
  const closeCurrentTab = useCloseCurrentTab();

  const navigate = useNavigateWithSlug();

  const handleNewTab = () => {
    const newTab: Tab = {
      id: uuid(),
      type: "empty",
    };
    setTabs((prev) => [...prev, { ...newTab }]);
    setCurrentTab(newTab.id);

    navigate({ to: `/editor`, search: true });
  };

  const handleClose = (tab: Tab) => {
    if (currentTab && tab.id === currentTab.id) {
      closeCurrentTab();
    } else {
      setTabs((prevTabs) => prevTabs.filter((t) => t.id !== tab.id));
    }
  };

  const handleCloseOthers = (tab: Tab) => {
    setTabs([tab]);
    navigate(getTabLink(tab));
  };

  const handleCloseToTheRight = (tab: Tab) => {
    const tabIndex = tabs.findIndex((t) => t.id === tab.id);
    if (tabIndex === -1) return;
    const slicedTabs = tabs.slice(0, tabIndex + 1);
    if (
      currentTab &&
      slicedTabs.find((x) => x.id === currentTab.id) === undefined
    ) {
      const newCurrentTab = slicedTabs[slicedTabs.length - 1];
      if (newCurrentTab) {
        setCurrentTab(newCurrentTab.id);
        navigate(getTabLink(newCurrentTab));
      } else {
        navigate({ to: `/editor` });
      }
    }
    setTabs(slicedTabs);
  };

  const handleCloseAll = () => {
    setTabs([]);
    navigate({ to: `/editor` });
  };

  const tabRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());
  const setTabRef = (node: HTMLDivElement | null, tabId: string) => {
    if (node) {
      tabRefs.current.set(tabId, node);
    } else {
      tabRefs.current.delete(tabId);
    }
  };

  useEffect(() => {
    if (!currentTab) {
      return;
    }
    const node = tabRefs.current.get(currentTab.id);
    if (node) {
      node.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [currentTab]);

  if (tabs.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="relative before:absolute before:inset-x-0 before:bottom-0 before:border-b before:dark:border-b-gray-700">
        <div className="flex max-w-full shrink-0 items-center space-x-3 overflow-x-auto pr-3">
          <Reorder.Group
            className="flex divide-x border-r"
            axis="x"
            values={tabs}
            onReorder={(newTabs) => {
              setTabs(newTabs);
            }}
          >
            {tabs.map((tab, idx) => (
              <Reorder.Item key={tab.id} value={tab} animate={false}>
                <ContextMenu.Root modal={false}>
                  <ContextMenu.Trigger>
                    <TabItem
                      ref={(node) => setTabRef(node, tab.id)}
                      tab={tab}
                      isActive={currentTab?.id === tab.id}
                    />
                  </ContextMenu.Trigger>
                  <ContextMenu.Portal>
                    <ContextMenu.Content className="min-w-[220px] overflow-hidden rounded border bg-white p-1 text-sm leading-6 shadow-xl dark:bg-gray-800">
                      <ContextMenu.Item
                        onClick={() => handleClose(tab)}
                        className="rounded px-2 py-px data-[highlighted]:bg-blue-500 data-[highlighted]:text-white"
                      >
                        Close
                      </ContextMenu.Item>
                      <ContextMenu.Item
                        onClick={() => handleCloseOthers(tab)}
                        className="rounded px-2 py-px data-[highlighted]:bg-blue-500 data-[highlighted]:text-white"
                      >
                        Close others
                      </ContextMenu.Item>
                      <ContextMenu.Item
                        disabled={idx === tabs.length - 1}
                        onClick={() => handleCloseToTheRight(tab)}
                        className="rounded px-2 py-px data-[disabled]:pointer-events-none data-[highlighted]:bg-blue-500 data-[highlighted]:text-white data-[disabled]:opacity-50"
                      >
                        Close tabs to the right
                      </ContextMenu.Item>
                      <ContextMenu.Item
                        onClick={() => handleCloseAll()}
                        className="rounded px-2 py-px data-[highlighted]:bg-blue-500 data-[highlighted]:text-white"
                      >
                        Close all
                      </ContextMenu.Item>
                    </ContextMenu.Content>
                  </ContextMenu.Portal>
                </ContextMenu.Root>
              </Reorder.Item>
            ))}
          </Reorder.Group>
          <div className="flex h-9 items-center">
            <IconButton
              onClick={handleNewTab}
              icon={<PlusIcon className="h-4 w-4" />}
              variant="outline"
              size="sm"
              className="h-6 w-6 min-w-0"
            />
          </div>
        </div>
      </div>
      {currentTab && <CurrentTabBar tab={currentTab} />}
    </div>
  );
};

function CurrentTabBar(props: { tab: Tab }) {
  if (!["model", "dashboard"].includes(props.tab.type)) {
    return null;
  }
  return (
    <div className="flex h-6 items-center bg-white pl-3 pr-4 text-xs leading-6 text-gray-500 dark:bg-[#1e1e1e] dark:text-gray-400">
      <TabPath {...props.tab} />
      {props.tab.modelId && (
        <>
          <hr className="mx-5 h-1/2 border-l" />
          <div className="flex items-center">
            <PublishStateBadge modelId={props.tab.modelId} />
          </div>
        </>
      )}

      {props.tab.modelId && (
        <>
          <hr className="mx-5 h-1/2 border-l" />
          <CoreMetricBadge modelId={props.tab.modelId} />
        </>
      )}
    </div>
  );
}

function TabPath(props: Omit<Tab, "id">) {
  if (props.type === "model" && props.modelId) {
    return <ModelTabPath {...props} modelId={props.modelId} />;
  }
  if (props.type === "dashboard" && props.dashboardId) {
    return <DashboardTabPath {...props} dashboardId={props.dashboardId} />;
  }
  return null;
}

function FolderPathToken(props: {
  folder: Pick<
    ModelFolder,
    "id" | "type" | "name" | "emoji" | "parentId" | "DWName"
  >;
}) {
  return (
    <div key={props.folder.id} className="flex items-center">
      <FolderIcon emoji={props.folder.emoji ?? undefined} className="mr-1.5" />
      {props.folder.name}
      <ChevronRightIcon className="h-5 w-5" />
    </div>
  );
}

function ModelTabPath(props: Omit<Tab, "id"> & { modelId: string }) {
  const { model } = useModel(props.modelId);

  const { dashboard } = useDashboard(model?.dashboardId ?? props.dashboardId);
  const modelPath = useFullModelPathRenderer(model);

  const renderPath = () => {
    if (model) {
      if (dashboard) {
        return (
          <>
            <FolderPathRenderer folderId={dashboard.folderId}>
              {(folder) => <FolderPathToken key={folder.id} folder={folder} />}
            </FolderPathRenderer>
            <div className="flex items-center">
              <DashboardIcon className="mr-1.5 h-4 w-4" />
              <span>{dashboard.name}</span>
              <ChevronRightIcon className="h-5 w-5" />
            </div>
            <div className="flex items-center">
              <ModelIcon model={model} className="mr-1.5 h-4 w-4" />
              <span>{model.name}</span>
            </div>
          </>
        );
      }
      if (isDraftModel(model)) {
        return (
          <span>
            <span className="font-semibold">Saved draft:</span> {modelPath}
          </span>
        );
      }
      return (
        <>
          <FolderPathRenderer folderId={model?.folder?.id}>
            {(folder) => <FolderPathToken key={folder.id} folder={folder} />}
          </FolderPathRenderer>
          <div className="flex items-center">
            <ModelIcon model={model} className="mr-1.5 h-4 w-4" />
            <span>{model.name}</span>
          </div>
        </>
      );
    }
    return <>{modelPath}</>;
  };
  return (
    <div className="flex items-center whitespace-nowrap">{renderPath()}</div>
  );
}

function DashboardTabPath(props: Omit<Tab, "id"> & { dashboardId: string }) {
  const { dashboard } = useDashboard(props.dashboardId);
  return (
    <div className="flex items-center">
      <FolderPathRenderer folderId={dashboard?.folderId}>
        {(folder) => <FolderPathToken key={folder.id} folder={folder} />}
      </FolderPathRenderer>
      <div className="flex items-center">
        <DashboardIcon className="mr-1.5 h-4 w-4" />
        <span>{dashboard?.name ?? props.label ?? "Unknown dashboard"}</span>
      </div>
    </div>
  );
}

function useDelayedRender(value: any, delay = 350) {
  const prevValue = usePrevious(value);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(false);
    const timer = setTimeout(() => setReady(true), delay);
    return () => clearTimeout(timer);
  }, [value, delay]);

  return {
    ready: prevValue === value && ready,
  };
}

const PublishStateBadge = memo((props: { modelId: string }) => {
  const state = useModelEditorState();
  const dispatch = useModelEditorDispatch();

  const isModelDirty = useModelEditorDirtyState();
  const [, setSidebarVisibility] = useHistorySidebar();
  const { model } = useModel(props.modelId);

  const { ready } = useDelayedRender(props.modelId);
  if (!ready || !state.initialized) return null;

  //don't show for drafts
  if (state.currentModelType === "draft") return null;
  //only show for models that have a published query.
  if (!model?.publishedQuery) return null;

  if (isModelDirty) {
    return (
      <Tooltip content="This model contains unpublished changes. Click to view the differences with the published version.">
        <Button
          variant="link"
          size="xs"
          className="font-normal focus:ring-offset-0"
          onClick={() => {
            dispatch({ type: "change_selected_history_item", payload: 0 });
            setSidebarVisibility(true);
          }}
          icon={<InformationCircleIcon className="mr-1 h-3 w-3" />}
        >
          Edited
        </Button>
      </Tooltip>
    );
  }
  return (
    <Tooltip content="This is a published model. It contains no unpublished changes.">
      <div className="flex items-center">
        <CheckCircleIcon className="mr-1 h-3 w-3" />
        Published
      </div>
    </Tooltip>
  );
});

const CoreMetricBadge = (props: { modelId: string }) => {
  const { model } = useModel(props.modelId);

  const [, setCoreMetricsOpen] = useCoreMetricsOpen();

  if (!model) return null;
  if (!model.templateItemId) return null; //Only show for models that are generated by core metrics. When the templateItemId is set, it means it was generated by core metrics.

  return (
    <Tooltip content="This model was generated by Marketing Core Metrics. Click to view.">
      <button
        onClick={() => {
          setCoreMetricsOpen(true);
        }}
        className="flex items-center space-x-1 underline"
      >
        <SquaresPlusIcon className="h-3 w-3" />
        <span>Marketing Metrics</span>
      </button>
    </Tooltip>
  );
};
