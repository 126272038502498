import { useCallback, useEffect } from "react";

import {
  Connection,
  ConnectionDocument,
  FindAvailableOperationsQuery,
  FindOneSyncQuery,
  ListSyncsDocument,
  ObjectType,
  OrchestrationSchedulerType,
  SubscriptionStatus,
  useCreateSyncMutation,
} from "@/apollo/types";
import { ActionButton, SecondaryButton } from "@/components/elements/Button";
import Select from "@/components/elements/Select";
import { useSidebar } from "@/components/elements/Sidebar";
import Center from "@/components/elements/layout/Center";
import StepLayout, { StepContainer } from "@/components/layouts/StepLayout";
import ConnectionSettings from "@/components/modules/ConnectionSettings";
import ModelOptionsSelecter from "@/components/modules/ModelOptionsSelecter";
import { SchedulerSelecter } from "@/components/modules/SchedulerSelecter";
import {
  SyncsBlockedBookMeetingButton,
  syncsBlockedMessages,
} from "@/components/modules/SyncsBlocked";
import { TaskAction } from "@/components/modules/TaskActionBar";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import { AdvancedSyncScheduler } from "@/components/modules/WeldSyncScheduler";
import { useOrchestrationFeatureGuard } from "@/features/feature-guards";
import { useSubscriptionStatus } from "@/features/subscription";
import classNames from "@/helpers/classNames";
import syncOperationExplainers from "@/helpers/syncOperationExplainers";
import useConnectionSettings from "@/hooks/useConnectionSettings";
import { useDisclosure } from "@/hooks/useDisclosure";
import useLoadingManager from "@/hooks/useLoadingManager";
import { useMountEffect } from "@/hooks/useMountEffect";
import { IntegrationLogo } from "@/integrations";
import { NewConnectionSlideOver } from "@/pages/Connections/NewConnectionSlideOver";
import HRWithText from "@/pages/Setup/components/HRWithText";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Search, useNavigate, useSearch } from "@tanstack/react-location";

import useDestinationProperties from "./hooks/useDestinationProperties";
import useDestinations from "./hooks/useDestinations";
import useGenerateOperationInputArray from "./hooks/useGenerateOperationInputArray";
import useObjectTypes from "./hooks/useObjectTypes";
import useOperations from "./hooks/useOperations";
import useSyncValidated from "./hooks/useSyncValidated";
import MappingStep from "./modules/MappingStep";
import { useSyncContext } from "./modules/SyncContext";
import SyncStateProvider from "./modules/SyncStateProvider";

export function NewRerverseEltSyncPage() {
  const { toggleCollapseState } = useSidebar();
  const navigate = useNavigate();

  const searchParams = useSearch<
    Search<
      Partial<{
        redirect: string;
      }>
    >
  >();

  useMountEffect(() => toggleCollapseState(true));

  const account = useCurrentAccount();

  return (
    <StepLayout
      steps={[
        {
          id: "connection",
          name: "Connection and object",
          description: "Where do you want to sync your data to?",
        },
        {
          id: "model",
          name: "Model",
          description: "What data do you want to sync?",
        },
        {
          id: "operation",
          name: "Sync operation",
          description: "How do you want us to sync your data? ",
        },
        {
          id: "schedule",
          name: "Schedule",
          description: "How often do you want your sync to run?",
        },
        {
          id: "mapping",
          name: "Mapping",
          description: "How should the fields be matched?",
        },
      ]}
      onCancel={
        searchParams.redirect
          ? () => navigate({ to: searchParams.redirect })
          : undefined
      }
    >
      {account.accountSettings.deactivateReverseELTSyncs ? (
        <SyncsBlockedMessage />
      ) : (
        <SyncStateProvider>
          <NewReverseEltSync />
        </SyncStateProvider>
      )}
    </StepLayout>
  );
}

function SyncsBlockedMessage() {
  const { status } = useSubscriptionStatus();
  return (
    <Center className="h-full w-full">
      <div className="flex flex-col items-center gap-4 text-gray-500 dark:text-gray-400">
        <h3 className="text-lg font-medium">
          {syncsBlockedMessages.REVERSE_ETL_SYNCS_BLOCKED_HEADING}
        </h3>
        {status === SubscriptionStatus.Freemium ? (
          <div className="flex flex-col items-center gap-4">
            <p>
              To keep using our paid features, upgrade to one of our paid plans.
            </p>
            <UpgradeNowButton source="new export page" />
            <HRWithText>Or</HRWithText>
            <SyncsBlockedBookMeetingButton />
          </div>
        ) : (
          <>
            <p>{syncsBlockedMessages.REVERSE_ETL_SYNCS_BLOCKED_BODY}</p>
            <SyncsBlockedBookMeetingButton />
          </>
        )}
      </div>
    </Center>
  );
}

function NewReverseEltSync() {
  const toast = useToast();

  const [state, dispatch] = useSyncContext();

  const searchParams = useSearch<
    Search<
      Partial<{
        connection: string;
        redirect: string;
        new_connection: string;
      }>
    >
  >();
  useEffect(() => {
    if (!searchParams.connection) return;
    dispatch({
      type: "change_destination",
      payload: searchParams.connection as string,
    });
  }, [searchParams.connection, dispatch]);

  const { destinations, destinationsLoading, currentDestination } =
    useDestinations();
  const { objectTypes, objectTypesLoading, currentObjectType } =
    useObjectTypes();
  const connectionSettings = useConnectionSettings(
    state.destinationId,
    "Activate",
    {
      objectType: currentObjectType,
    },
  );
  const { findAllAvailableOperations, operationsLoading, currentOperation } =
    useOperations();

  const { destinationProperties } = useDestinationProperties();

  const navigate = useNavigate();
  const [createSync, { loading: creating }] = useCreateSyncMutation({
    onCompleted(data) {
      if (searchParams.redirect) {
        navigate({ to: searchParams.redirect });
      } else {
        navigate({ to: `../${data.createSync.id}` });
      }
    },
    onError(error) {
      toast(`Sync not created`, error.message, "error");
    },
    refetchQueries: [
      { query: ListSyncsDocument },
      { query: ConnectionDocument, variables: { id: state.destinationId } },
    ],
  });

  const onChangeQuery = useCallback(
    (modelId: string) => dispatch({ type: "change_query", payload: modelId }),
    [dispatch],
  );
  const onChangeDestination = useCallback(
    (connectionId: string | null) =>
      dispatch({
        type: "change_destination",
        payload: connectionId || "",
      }),
    [dispatch],
  );

  const refetchConnectionSettings = connectionSettings.refetch;
  const onChangeObjectType = useCallback(
    (item: ObjectType | null) => {
      refetchConnectionSettings({ objectType: item });
      return dispatch({
        type: "change_object_type",
        payload: item?.objectName || "",
      });
    },
    [dispatch, refetchConnectionSettings],
  );
  const onChangeSyncOperation = useCallback(
    (item: FindAvailableOperationsQuery["findAllAvailableOperations"][0]) =>
      dispatch({
        type: "change_operation",
        payload: item ? { id: item.id, mode: item.operationMode } : null,
      }),
    [dispatch],
  );

  const generateOperationInputArray = useGenerateOperationInputArray();

  const generateConfig = useCallback(() => {
    let config: FindOneSyncQuery["findOneSync"]["config"] = {};
    if (connectionSettings.required) {
      config.destinationSettings = state.destinationSettings;
    }
    return JSON.stringify(config);
  }, [connectionSettings.required, state.destinationSettings]);

  const { dataWarehouseConnectionId } = useCurrentAccount();

  const handleSubmit = useCallback(async () => {
    const missingMappings = destinationProperties
      .filter((dp) => dp.isRequired)
      .filter(
        (dp) =>
          !state.mapping.some(
            (mapping) => mapping.destinationPropertyId === dp.propertyId,
          ),
      );
    if (missingMappings.length > 0) {
      const missingString = missingMappings
        .map((dp) => `"${dp.propertyName}"`)
        .join(", ");
      toast(
        "Missing mappings",
        `Missing destination mappings for: ${missingString}`,
        "error",
      );
      return;
    }
    if (!dataWarehouseConnectionId)
      throw new Error(
        "No dataWarehouseConnectionId on account - needed for Reverse ETL sync",
      );
    createSync({
      variables: {
        sourceConnectionId: dataWarehouseConnectionId,
        destinationConnectionId: state.destinationId,
        modelId: state.queryId,
        operations: generateOperationInputArray(),
        primaryObject: state.objectTypeName,
        syncInterval: state.scheduleKey,
        config: generateConfig(),
        schedulerType: state.schedulerType,
        orchestrationWorkflowId: state.orchestrationWorkflowId,
      },
    });
  }, [
    dataWarehouseConnectionId,
    createSync,
    destinationProperties,
    generateConfig,
    generateOperationInputArray,
    state.destinationId,
    state.mapping,
    state.objectTypeName,
    state.queryId,
    state.scheduleKey,
    state.schedulerType,
    state.orchestrationWorkflowId,
    toast,
  ]);

  const {
    syncValidated,
    settingsValidated,
    handleChangeConnectionSettingsValid,
  } = useSyncValidated();

  const { isEnabled: isOrchestrationEnabled } = useOrchestrationFeatureGuard();

  useEffect(() => {
    if (
      isOrchestrationEnabled === false &&
      state.schedulerType === OrchestrationSchedulerType.Global
    ) {
      dispatch({
        type: "update_scheduler_type",
        payload: {
          schedulerType: OrchestrationSchedulerType.Local,
        },
      });
    }
  }, [isOrchestrationEnabled, state.schedulerType, dispatch]);

  const addConnectionSlideOverDisclosure = useDisclosure({
    defaultState: {
      isOpen: searchParams.new_connection !== undefined,
    },
  });

  return useLoadingManager(
    {
      loading: destinationsLoading,
      message: "Loading connections",
    },
    () => (
      <>
        <div className="space-y-8">
          <StepContainer id="model" isCompleted={!!state.queryId}>
            <div className="max-w-2xl space-y-8">
              <ModelOptionsSelecter
                selectedModelId={state.queryId}
                onSelect={(model) => onChangeQuery(model.id)}
              />
            </div>
          </StepContainer>
          <StepContainer
            id="connection"
            isCompleted={
              !!state.destinationId &&
              !!state.objectTypeName &&
              settingsValidated
            }
          >
            <div className="max-w-2xl space-y-8">
              <div className="flex items-center space-x-2">
                <div className="grow">
                  <Select
                    autoFocus
                    placeholder="Select an existing connector..."
                    value={currentDestination}
                    getOptionLabel={(option: any) =>
                      option.label || option.integrationId
                    }
                    iconParser={(item: Connection) => (
                      <IntegrationLogo
                        id={item.integrationId}
                        className="h-8"
                      />
                    )}
                    options={destinations}
                    onChange={(item?: Connection) =>
                      onChangeDestination(item?.id || null)
                    }
                    autoSelectOnSingleOption
                  />
                </div>
                <div className="flex items-center text-xs dark:text-gray-300">
                  or
                </div>
                <SecondaryButton
                  tabIndex={-1}
                  icon={<PlusIcon />}
                  onClick={() => {
                    addConnectionSlideOverDisclosure.onOpen();
                  }}
                >
                  Add new connection
                </SecondaryButton>
              </div>

              <Select
                disabled={!state.destinationId || connectionSettings.loading}
                isLoading={objectTypesLoading || connectionSettings.loading}
                placeholder="Select your object"
                labelKey="objectName"
                options={objectTypes}
                value={currentObjectType}
                onChange={onChangeObjectType}
                autoSelectOnSingleOption
              />

              {currentDestination && (
                <ConnectionSettings
                  key={currentDestination.id}
                  onChange={(payload) =>
                    dispatch({ type: "change_destination_settings", payload })
                  }
                  connectionSettings={connectionSettings}
                  onChangeValid={handleChangeConnectionSettingsValid}
                  initialValues={state.destinationSettings}
                />
              )}
            </div>
            <NewConnectionSlideOver
              show={addConnectionSlideOverDisclosure.isOpen}
              integrationId={
                typeof searchParams.new_connection === "string" &&
                searchParams.new_connection.length > 0
                  ? searchParams.new_connection
                  : undefined
              }
              onClose={() => {
                addConnectionSlideOverDisclosure.onClose();
                navigate({
                  search(prev) {
                    return {
                      ...prev,
                      new_connection: undefined,
                    };
                  },
                });
              }}
              abilityFilter="ReverseEltDestinationConnection"
              onConnectionAdded={(connection) => {
                onChangeDestination(connection.id);
              }}
              resetOnClose={true}
            />
          </StepContainer>

          <StepContainer
            id="operation"
            isCompleted={!!state.operation}
            disabled={!state.objectTypeName || !settingsValidated}
          >
            <div className="grid max-w-2xl grid-cols-3 gap-4">
              {operationsLoading && <>Loading..</>}
              {!operationsLoading &&
                findAllAvailableOperations &&
                findAllAvailableOperations.map((item) => (
                  <button
                    key={item.id}
                    className={classNames(
                      "flex flex-col items-center justify-start rounded-sm border p-4 transition-colors hover:border-primary dark:hover:border-primary",
                      currentOperation?.operationMode === item.operationMode
                        ? "border-primary"
                        : "border-gray-200 dark:border-gray-700",
                    )}
                    onClick={() => onChangeSyncOperation(item)}
                  >
                    <p className="capitalize">{item.operationMode}</p>
                    <p>
                      <span className="text-xs text-gray-600 dark:text-gray-400">
                        {syncOperationExplainers(item.operationMode)}
                      </span>
                    </p>
                  </button>
                ))}
            </div>
          </StepContainer>
          <StepContainer
            id="schedule"
            isCompleted={
              state.schedulerType === OrchestrationSchedulerType.Global
                ? !!state.orchestrationWorkflowId
                : !!state.scheduleKey
            }
          >
            <div className="max-w-2xl space-y-8">
              {isOrchestrationEnabled && (
                <div className="max-w-lg">
                  <SchedulerSelecter
                    configuration={{
                      allowNewWorkflow: false,
                      allowNoSchedule: false,
                    }}
                    orchestrationScheduler={state.schedulerType}
                    orchestrationWorkflowId={state.orchestrationWorkflowId}
                    onUpdate={(config) => {
                      if (!config.orchestrationScheduler) return;
                      dispatch({
                        type: "update_scheduler_type",
                        payload: {
                          schedulerType: config.orchestrationScheduler,
                          orchestrationWorkflowId:
                            config.orchestrationWorkflowId,
                        },
                      });
                    }}
                  />
                </div>
              )}
              {state.schedulerType === OrchestrationSchedulerType.Local && (
                <div className="max-w-lg">
                  <label className="mb-1 block text-xs font-bold dark:text-gray-400">
                    Sync frequency
                  </label>
                  <AdvancedSyncScheduler
                    cron={state.scheduleKey}
                    onChange={(cron) => {
                      dispatch({
                        type: "change_schedule",
                        payload: cron,
                      });
                    }}
                    startNowDescription="Will sync now and update data:"
                  />
                </div>
              )}
            </div>
          </StepContainer>
          <StepContainer
            id="mapping"
            isCompleted={syncValidated}
            disabled={
              !(state.operation && state.scheduleKey && state.objectTypeName)
            }
            action={
              <TaskAction>
                <ActionButton
                  onClick={handleSubmit}
                  disabled={!syncValidated || creating}
                  loadingText="Creating sync"
                  isLoading={creating}
                >
                  Create sync
                </ActionButton>
              </TaskAction>
            }
          >
            <MappingStep />
          </StepContainer>
        </div>
      </>
    ),
  );
}
