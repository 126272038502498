import dayjs, { Dayjs } from "dayjs";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useEffect, useMemo } from "react";

import { ListModelsQuery, useUserQuery } from "@/apollo/types";
import { WeldTab } from "@/components/elements/tabs/Tab";
import {
  useOpenDraftInTab,
  useOpenModelInTab,
} from "@/components/modules/ModelTabs";
import { useDataSourceSlideOver } from "@/components/modules/new-data-source-slideover";
import {
  ConnectorRecommendation150More,
  ConnectorRecommendationItem,
  useConnectorRecommendations,
} from "@/features/connectors";
import classNames from "@/helpers/classNames";
import { dateTimeFormat } from "@/helpers/dateFormat";
import { useRecentModels } from "@/hooks/useRecentModels";
import { useEltSyncs } from "@/hooks/useSync";
import { useMixpanel } from "@/monitoring/mixpanel";
import HRWithText from "@/pages/Setup/components/HRWithText";
import { useCurrentUser } from "@/providers/UserProvider";
import { createStorageKey } from "@/utils/storage";
import { Tab } from "@headlessui/react";
import { DocumentIcon } from "@heroicons/react/24/outline";

import { useEditorState } from "../../EditorStore";
import {
  useModelEditorDispatch,
  useModelEditorState,
} from "../../ModelEditorStore";
import {
  DraftModel,
  useCreateDraftModel,
  useDrafts,
} from "../../QueryEditor/useModelDraft";
import { useDataSourcesFolders } from "../../Sidebar/data-sources/useDataSourcesFolders";
import { useModelDisplayName } from "../../hooks/useFullPathNames";
import {
  useListModels,
  useListPublishedModels,
} from "../../hooks/useListModels";
import { useAssistantSidebar } from "../../model-generator/assistant";
import { ModelIcon } from "../ModelIcon/ModelIcon";
import {
  AddDemoDataButton,
  ConnectFirstDataSourceButton,
  CreateModelButton,
  MeetEdButton,
} from "./ActionButtonCards";

export const EditorEmptyState = () => {
  const state = useModelEditorState();
  const dispatch = useModelEditorDispatch();
  const [, editorStateDispatch] = useEditorState();
  const { onOpen: onOpenAssistant } = useAssistantSidebar();

  useEffect(() => {
    if (state.initialized) {
      dispatch({
        type: "reset",
      });
    }
  }, [dispatch, state.initialized]);
  useEffect(() => {
    editorStateDispatch({
      type: "set_empty",
    });
  }, [editorStateDispatch]);

  const openDraftInTab = useOpenDraftInTab();

  const mixpanel = useMixpanel();

  const createDraft = useCreateDraftModel();
  const handleNew = async () => {
    const draft = createDraft();
    openDraftInTab(draft.id, draft.number);
    mixpanel.track("Draft Created", {
      source: "blank model tab",
    });
  };

  const { hasData, loading } = useCheckHasData();
  const hasSync = useHasOneOrMoreSyncs();

  const dataSourceSlideOver = useDataSourceSlideOver();
  const recommendations = useConnectorRecommendations({
    ability: "EltSourceConnection",
    limit: 4,
  });
  return (
    <>
      <div className="h-full grow overflow-auto p-10">
        <div className="mx-auto flex h-full max-w-5xl flex-col items-center space-y-8">
          <div className="flex flex-grow flex-col items-center justify-center">
            <div className="flex grow-0 flex-col gap-8 lg:flex-row">
              {!loading && (
                <>
                  {hasData && <CreateModelButton onClick={handleNew} />}
                  {hasSync && (
                    <MeetEdButton
                      onClick={() => {
                        handleNew();
                        onOpenAssistant();
                      }}
                    />
                  )}
                  {!hasSync && (
                    <ConnectFirstDataSourceButton
                      onClick={() => dataSourceSlideOver.onOpen()}
                    />
                  )}
                  {!hasData && <AddDemoDataButton />}
                </>
              )}
            </div>
            <div>
              {hasData && (
                <div className="mt-10 flex flex-col items-center">
                  <HRWithText className="mb-5 text-sm">
                    Connect a data source
                  </HRWithText>
                  <div className="flex flex-wrap justify-center gap-4">
                    {recommendations.map((integration) => (
                      <ConnectorRecommendationItem
                        key={integration.id}
                        size="lg"
                        integration={integration}
                        onClick={() =>
                          dataSourceSlideOver.onOpen({
                            integrationId: integration.id,
                          })
                        }
                      />
                    ))}
                    <ConnectorRecommendation150More
                      size="lg"
                      onClick={() => dataSourceSlideOver.onOpen()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <RecentModels />
        </div>
      </div>
    </>
  );
};

const recentModelsIndexAtom = atomWithStorage<number>(
  createStorageKey("recent-models-index"),
  0,
);

const RecentModels = () => {
  const recentlyPublished = useRecentlyPublishedModels();
  const recenentlyEdited = useRecentlyEditedModels();

  const [recentIndex, setRecentIndex] = useAtom(recentModelsIndexAtom);

  if (!recentlyPublished.length && !recenentlyEdited.length) return null;

  return (
    <div className="w-full max-w-3xl">
      {recentlyPublished.length === 0 ? (
        <>
          <div className="pl-2 text-xs">Recently edited models</div>
          <RecentlyEditedList recenentlyEdited={recenentlyEdited} />
        </>
      ) : (
        <Tab.Group
          defaultIndex={recentIndex}
          onChange={(index) => {
            setRecentIndex(index);
          }}
        >
          <Tab.List className="mb-2 flex gap-4">
            <WeldTab buttonSize="sm">Recently published</WeldTab>
            <WeldTab buttonSize="sm">Recently edited by me</WeldTab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <RecentlyPublishedList recentlyPublished={recentlyPublished} />
            </Tab.Panel>
            <Tab.Panel>
              <RecentlyEditedList recenentlyEdited={recenentlyEdited} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      )}
    </div>
  );
};

const RecentlyPublishedList = (props: { recentlyPublished: RecentModel[] }) => {
  return (
    <div className="w-full">
      {props.recentlyPublished.map((modelRow) => {
        return (
          <ModelRow
            key={modelRow.model.id}
            model={modelRow.model}
            updatedAt={modelRow.updatedAt}
          />
        );
      })}
    </div>
  );
};

const RecentlyEditedList = (props: { recenentlyEdited: RecentModelRow[] }) => {
  return (
    <div className="w-full">
      {props.recenentlyEdited.map((modelRow) => {
        if (modelRow.type === "draft")
          return (
            <DraftModelRow
              key={modelRow.model.id}
              draft={modelRow.model}
              updatedAt={modelRow.updatedAt}
            />
          );
        else
          return (
            <ModelRow
              key={modelRow.model.id}
              model={modelRow.model}
              updatedAt={modelRow.updatedAt}
            />
          );
      })}
    </div>
  );
};

const ModelRow = (props: {
  model: ListModelsQuery["models"][0];
  updatedAt: Dayjs;
}) => {
  const user = useUser(props.model.publishedQuery?.userId ?? undefined);

  const { modelName, pathName } = useModelDisplayName(props.model.id);

  const openModel = useOpenModelInTab();

  return (
    <button
      className={classNames(
        "flex h-12 w-full cursor-pointer items-center space-x-4 rounded-sm px-4 py-4 hover:bg-gray-100 dark:hover:bg-gray-700",
        "ring-offset-white focus:outline-none focus:ring-2 focus:ring-primary-light dark:ring-offset-gray-900",
      )}
      onClick={() => {
        openModel({ modelId: props.model.id });
      }}
    >
      <div>
        <ModelIcon model={props.model} />
      </div>
      <div className="grow text-left">
        <div className="text-xs text-gray-600 dark:text-gray-400">
          {pathName}
        </div>
        <div className="text-xs font-medium">{modelName}</div>
      </div>
      <div className="text-right text-xs text-gray-600 dark:text-gray-400">
        {props.updatedAt.format(dateTimeFormat)}{" "}
        {user && `by ${user.firstName} ${user.lastName}`}
      </div>
    </button>
  );
};

const DraftModelRow = (props: { draft: DraftModel; updatedAt: Dayjs }) => {
  const user = useCurrentUser();

  const openDraft = useOpenDraftInTab();

  return (
    <button
      className={classNames(
        "flex w-full cursor-pointer items-center space-x-4 rounded-sm px-4 py-4 hover:bg-gray-100 dark:hover:bg-gray-700",
        "ring-offset-white focus:outline-none focus:ring-2 focus:ring-primary-light dark:ring-offset-gray-900",
      )}
      onClick={() => {
        openDraft(props.draft.id, props.draft.number);
      }}
    >
      <DocumentIcon className="h-4 w-4" />
      <div className="grow text-left text-xs font-medium">
        Unsaved draft {props.draft.number > 0 && `(${props.draft.number})`}
      </div>
      <div className="text-right text-xs text-gray-600 dark:text-gray-400">
        {props.updatedAt.format(dateTimeFormat)}{" "}
        {user && `by ${user.firstName} ${user.lastName}`}
      </div>
    </button>
  );
};

type RecentModel = {
  type: "model";
  model: ListModelsQuery["models"][0];
  updatedAt: Dayjs;
};

type RecentDraft = {
  type: "draft";
  model: DraftModel;
  updatedAt: Dayjs;
};
type RecentModelRow = RecentModel | RecentDraft;

/*
Return up to 5 models that have been recently published
*/
const useRecentlyPublishedModels = () => {
  const { publishedModels } = useListPublishedModels();
  return useMemo(() => {
    return publishedModels
      .sort(
        (a, b) =>
          new Date(b.publishedQuery?.createdAt).getTime() -
          new Date(a.publishedQuery?.createdAt).getTime(),
      )
      .slice(0, 5)
      .map((model) => {
        return {
          type: "model",
          model,
          updatedAt: dayjs(model.publishedQuery?.createdAt || model.createdAt),
        } as RecentModel;
      });
  }, [publishedModels]);
};

/*
Return up to 5 models that have been recently edited by the current user
*/
const useRecentlyEditedModels = () => {
  const { models } = useListModels();
  const { drafts } = useDrafts();

  const recentList = useRecentModels();

  return useMemo(() => {
    const recentModels: RecentModelRow[] = [];
    recentList.forEach((recentModel) => {
      const model = models.find((model) => model.id === recentModel.modelId);
      if (model) {
        recentModels.push({
          type: "model",
          model,
          updatedAt: dayjs(recentModel.timestamp),
        });
        return;
      }
      const draft = drafts.find((draft) => draft.id === recentModel.modelId);
      if (draft)
        recentModels.push({
          type: "draft",
          model: draft,
          updatedAt: dayjs(recentModel.timestamp),
        });
    });

    return recentModels.slice(0, 5);
  }, [drafts, models, recentList]);
};

const useUser = (userId?: string) => {
  const { data } = useUserQuery({
    variables: { userId: userId ?? "" },
    skip: !userId,
  });
  return data?.user;
};

export type FormType = {
  name: string;
  selectedBaseFolder: string;
  selectedSubFolder: string;
};

const useCheckHasData = () => {
  const { dataSourcesFolders, loading } = useDataSourcesFolders();

  return useMemo(() => {
    const hasData = dataSourcesFolders.length > 0;
    return { loading, hasData };
  }, [dataSourcesFolders, loading]);
};

const useHasOneOrMoreSyncs = () => {
  const { eltSyncs } = useEltSyncs();
  return eltSyncs.length > 0;
};
