import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "./index.css";

import { ref, update } from "firebase/database";
import produce from "immer";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import GridLayout, { Responsive, WidthProvider } from "react-grid-layout";
import { useDebounce } from "use-debounce";

import { useQueryModelQuery } from "@/apollo/types";
import { IconButton } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { useFirebaseDatabase } from "@/features/firebase";
import classNames from "@/helpers/classNames";
import useSize from "@/hooks/useSize";
import { useCurrentAccount } from "@/providers/account";
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  ChartBarSquareIcon,
  Cog6ToothIcon,
  DocumentChartBarIcon,
} from "@heroicons/react/24/outline";

import { useModel } from "../hooks/useCurrentModel";
import { AddNewVisualization } from "./EditVisualization/AddNewVisualization";
import { EditVisualizationSidebar } from "./EditVisualization/EditVisualization";
import { Dashboard, Tile, VisualizationType } from "./VisualizationType";
import { BarChartComponent } from "./types/BarChart";
import { LineChartComponent } from "./types/LineChart";
import MetricComponent from "./types/MetricComponent";
import { TableComponent } from "./types/Table";
import { useInitializeDashboard } from "./useDashboards";

const ResponsiveGridLayout = WidthProvider(Responsive);

type UpdateObject = Record<string, number | string | null>;

type VisualizeProps = {
  dashboard: Dashboard;
  showTileActionsOnHover?: boolean;
};

export default function Visualize({
  dashboard,
  showTileActionsOnHover = true,
}: VisualizeProps) {
  const [isAdding, setIsAdding] = useState(false);

  useInitializeDashboard(dashboard);

  const tiles = useMemo(() => {
    const entries = Object.entries(dashboard?.tiles || {});
    const tiles = entries.map(([id, tile]) => ({
      ...tile,
      name: tile.name ?? "", //todo: remove unnecessary check after migration
      i: id,
    }));
    return tiles;
  }, [dashboard?.tiles]);

  const onLayoutChange = useTrackLayoutChanges(dashboard);

  //scroll to new tiles:
  const containerRef = useRef<HTMLDivElement>(null);
  let tileLength = useRef(tiles.length);
  useLayoutEffect(() => {
    if (tileLength.current < tiles.length) {
      //scroll to bottom
      containerRef.current?.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    tileLength.current = tiles.length;
  }, [tiles]);

  //Force the grid to re-render when the width of the container changes
  const containerSize = useSize(containerRef);
  const widthDebounced = useDebounce(containerSize?.width, 500);

  useEffect(() => {
    //force react-grid to register size change when the width of the container size changes
    gridRef.current?.onWindowResize();
  }, [widthDebounced]);

  const gridRef = useRef<any>(null);

  return (
    <div className="h-full w-full overflow-auto" ref={containerRef}>
      <div className="relative flex items-center justify-between px-10 pt-10">
        <div className="text-lg font-medium">
          {dashboard.name ?? "Unnamed dashboard"}
        </div>
        <div className="flex items-center">
          <AddNewVisualization
            show={isAdding}
            onClose={setIsAdding}
            dashboardId={dashboard.id}
          />
        </div>
      </div>
      {tiles.length === 0 && (
        <div className="m-10 flex flex-1">
          <button
            onClick={() => {
              setIsAdding(true);
            }}
            className="flex w-full flex-col items-center rounded-md border border-dotted border-gray-400 p-24 hover:border-blue-500 dark:border-gray-500 dark:hover:border-blue-500 md:w-6/12"
          >
            <ChartBarSquareIcon className="mb-10 h-10 w-10" />
            <p className="mb-2 text-center text-lg text-black dark:text-white">
              Add your first chart
            </p>
            <p className="text-center text-sm text-gray-500 dark:text-gray-400">
              Select a model and visualization type
            </p>
          </button>
        </div>
      )}
      <ResponsiveGridLayout
        ref={gridRef}
        className="layout"
        layouts={{ lg: tiles.map((t) => ({ ...t, minW: 4, minH: 3 })) }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 18, md: 18, sm: 18, xs: 18, xxs: 18 }}
        rowHeight={30}
        margin={{ lg: [40, 40], md: [30, 30], sm: [20, 20], xs: [10, 10] }}
        autoSize={true}
        draggableHandle=".draggable"
        onLayoutChange={onLayoutChange}
      >
        {tiles.map((tile) => (
          <div
            key={tile.i}
            className="relative rounded-lg border border-gray-100 hover:border-primary-light dark:border-gray-700 dark:hover:border-primary"
            style={{ overflow: "hidden" }}
          >
            <DashboardTile
              tile={tile}
              dashboardId={dashboard.id}
              showActionsOnHover={showTileActionsOnHover}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
}

const DashboardTile = (props: {
  tile: Tile;
  dashboardId: string;
  showActionsOnHover: boolean;
}) => {
  const [editing, setEditing] = useState(false);

  const { data, error, loading, refetch } = useTileData(props.tile);

  const openModelInTab = useOpenModelInTab();
  const { model } = useModel(props.tile.modelId ?? "");

  return (
    <>
      <div
        className="group flex h-full flex-col bg-white dark:bg-gray-800"
        key={props.tile.i}
      >
        <div className="draggable text-md flex-shrink-0 px-4 pt-4 font-medium text-gray-800 dark:text-gray-100 lg:px-8 lg:pt-8">
          {props.tile.name || "unnamed tile"}
        </div>

        <div
          className={classNames(
            "absolute right-0 top-0 z-10 mr-6 mt-7 flex items-center justify-end bg-white group-hover:opacity-100 dark:bg-gray-800",
            props.showActionsOnHover ? "opacity-0" : "opacity-50",
          )}
        >
          <Tooltip content={"Refresh data"}>
            <IconButton
              onClick={() => {
                refetch();
              }}
              className={classNames(
                "transition-opacity group-hover:opacity-100",
                props.showActionsOnHover ? "opacity-0" : "opacity-50",
              )}
              variant="ghost"
              size="sm"
              disabled={loading}
              icon={<ArrowPathIcon className="w-4 text-gray-500" />}
            />
          </Tooltip>
          {!!model?.dashboardId && (
            <>
              {!!props.tile.modelId && (
                <Tooltip content={"Open Custom SQL in new tab"}>
                  <IconButton
                    onClick={() => {
                      openModelInTab({
                        modelId: props.tile.modelId!,
                        type: "model",
                        dashboardId: props.dashboardId,
                        tileId: props.tile.i,
                      });
                    }}
                    className={classNames(
                      "transition-opacity group-hover:opacity-100",
                      props.showActionsOnHover ? "opacity-0" : "opacity-50",
                    )}
                    variant="ghost"
                    size="sm"
                    icon={
                      <DocumentChartBarIcon className="w-4 text-gray-500" />
                    }
                    data-stonly="visualize__open-model-in-new-tab"
                  />
                </Tooltip>
              )}
            </>
          )}
          {model && !model.dashboardId && (
            <>
              {!!props.tile.modelId && (
                <Tooltip content={"Open model in new tab"}>
                  <IconButton
                    onClick={() => {
                      openModelInTab({ modelId: props.tile.modelId! });
                    }}
                    className={classNames(
                      "transition-opacity group-hover:opacity-100",
                      props.showActionsOnHover ? "opacity-0" : "opacity-50",
                    )}
                    variant="ghost"
                    size="sm"
                    icon={
                      <ArrowTopRightOnSquareIcon className="w-4 text-gray-500" />
                    }
                    data-stonly="visualize__open-model-in-new-tab"
                  />
                </Tooltip>
              )}
            </>
          )}
          <Tooltip content={"Change graph settings"}>
            <IconButton
              onClick={() => {
                setEditing((p) => !p);
              }}
              className={classNames(
                "transition-opacity group-hover:opacity-100",
                props.showActionsOnHover ? "opacity-0" : "opacity-50",
              )}
              variant="ghost"
              size="sm"
              icon={<Cog6ToothIcon className="w-4 text-gray-500" />}
            />
          </Tooltip>
        </div>

        <div className="relative flex-grow overflow-hidden">
          <TileVisualization
            loading={loading}
            visualization={props.tile.visualization}
            data={data}
            error={error?.message}
          />
        </div>
      </div>
      <EditVisualizationSidebar
        isOpen={editing}
        tile={props.tile}
        dashboardId={props.dashboardId}
        onFinished={() => {
          setEditing(false);
        }}
      />
    </>
  );
};

const useTrackLayoutChanges = (dashboard: Dashboard) => {
  const database = useFirebaseDatabase();

  const account = useCurrentAccount();

  const tilesDoc = useMemo(() => {
    return ref(
      database,
      `workspaces/${account.id}/dashboards/${dashboard.id}/tiles`,
    );
  }, [database, account, dashboard]);

  const onLayoutChange = (layout: GridLayout.Layout[]) => {
    if (!tilesDoc || layout.length === 0) return;

    const updates = layout.reduce<UpdateObject>((obj, tile) => {
      return produce(obj, (draft) => {
        draft[`/${tile.i}/h`] = tile.h;
        draft[`/${tile.i}/w`] = tile.w;
        draft[`/${tile.i}/x`] = tile.x;
        draft[`/${tile.i}/y`] = tile.y;
      });
    }, {});

    update(tilesDoc, updates);
  };
  return onLayoutChange;
};

export const useTileData = (tile: Tile, skip?: boolean) => {
  const previewQueryQuery = useQueryModelQuery({
    variables: {
      modelId: tile.modelId ?? "",
    },
    skip: !tile.modelId || skip,
    notifyOnNetworkStatusChange: true,
  });

  const data = useMemo(() => {
    return previewQueryQuery.data?.queryModel.response ?? [];
  }, [previewQueryQuery.data?.queryModel.response]);

  return {
    data,
    error: previewQueryQuery.error,
    loading: previewQueryQuery.loading,
    refetch: previewQueryQuery.refetch,
  };
};

export const TileVisualization = (props: {
  visualization: Tile["visualization"];
  loading: boolean;
  data: any[];
  error?: string;
}) => {
  if (props.loading) {
    return (
      <div className="absolute inset-0 flex items-center justify-center bg-white opacity-75 dark:bg-gray-800">
        <LoadingSpinner className="h-6 w-6 dark:text-white" />
      </div>
    );
  }

  if (props.error) {
    return (
      <div className="absolute inset-0 flex h-full w-full items-center justify-center overflow-hidden p-8">
        <span className="text-sm italic text-red-500">{props.error}</span>
      </div>
    );
  }

  if (props.data.length === 0) {
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <div>No data available</div>
      </div>
    );
  }

  switch (props.visualization.type) {
    case VisualizationType.Table:
      return <TableComponent data={props.data} loading={props.loading} />;
    case VisualizationType.Bar:
      return (
        <BarChartComponent chart={props.visualization} data={props.data} />
      );
    case VisualizationType.Line:
      return (
        <LineChartComponent chart={props.visualization} data={props.data} />
      );
    case VisualizationType.Metric:
      return <MetricComponent chart={props.visualization} data={props.data} />;
  }
};
